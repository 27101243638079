@import '../../styles/utils.scss';

.el {
    width: 100%;
    margin-top:-2px;
    .container {
        display: flex;
        padding: 0;
        background-color: #fff;
        max-width: $container-fhd;
        @include tablet {
            height: inherit;
            flex-direction: column;
        }
        &.isMediaLeft {
            flex-direction:row;
            .text {
                // padding-left: 80px;
                // @include desktop {
                //     padding-left: $mobile-padding;
                // }
                @media screen and (min-width: $container-md) {
                  padding: 30px 96px;
                  padding-right: calc((100% - $container-md) / 2 + $container-p);
                }
            }
            // @include tablet {
            //     flex-direction: column-reverse;
            // }
            background-color: $color-grey;
        }
        // &:not(:first-child) {
        //     background-color: $color-grey;
        // }
        // &.quoteContainer {
        //     @include tablet {
        //         flex-direction: ;
        //     }
        // }
        &:nth-child(odd) {
            @include tablet {
                flex-direction: column;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
            @include tablet {
                flex-direction: column;
            }
        }
        &.onlyOne{
            @include tablet {
                flex-direction: column-reverse;
            }
        }
    }
    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 50%;
        padding: 30px 6.66vw;
        @media screen and (min-width: $container-md) {
          padding: 30px 96px;
          padding-left: calc((100% - $container-md) / 2 + $container-p);
        }
        @include desktop-l {
            // padding: 0 $container-p;
        }
        @include desktop {
            padding: 0 $container-p;
        }
        @include tablet{
            width: 100%;
            height: inherit;
            padding-top: 60px;
            padding-bottom: 80px;
            padding-left: $mobile-padding;
            padding-right: $mobile-padding;
        }
        @include mobile {
          min-height: 100vw;
        }
        &.videoText {
            background-color: $color-grey;
            @include tablet {
                padding: 60px 20px;
            }
            .title {
                width: 100%;
            }
            .desc {
                color: black;
            }
        }

        .title {
            width: 100%;
            @include desktop {
                width: 100%;
            }
        }
        .desc {
            margin-top: 15px;
            width: 100%;
            font-size: 18px;
            line-height: 23px;
            font-weight: 400;
            @include desktop {
                width: 100%;
            }
        }
    }
    .quote {
        // padding: 0 30px;
        @include desktop-l {
            // padding: 0 30px 0 0;
        }
        @include desktop {
            // padding: 0;
        }
        @include tablet{
            width: 100%;
            height: inherit;
            // padding-bottom: 20px;
            padding-left: 0;
            padding-right: 0;
        }
        .headline {
            font-style: italic;
            font-weight: 500;
            text-align: left;
            margin-top: 40px;
            @include desktop {
                margin-top: 20px;
            }
        }
        .name {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.03em;
            text-align: left;
            margin-top: 60px;
            @include  desktop {
                margin-top: 30px;
            }
        }
        .job {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            letter-spacing: 0.05em;
            text-align: left;
            margin-top: 5px;
        }
        .icon {
            margin-top: 30px;
            @include desktop {
                margin-top: 0;
            }
        }
    }
    .media {
        width: 50%;
        @include tablet{
            width: 100%;
            // height: 100vw;
        }
    }
    .banner {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        .deco {
            width: 82%;
        }
        padding-top: 100%;
    }
    .player {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        video {
            height: 100%;
        }
        .playButton {
            cursor: pointer;
            position: absolute;
        }
        .hidden {
            display: none;
        }
    }
    .vimeoPlayer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        video {
            height: 100%;
        }
        .playButton {
            cursor: pointer;
            position: absolute;
            pointer-events: none;
        }
        .hidden {
            display: none;
        }
    }
}
